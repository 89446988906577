import React from 'react';

const Footer = () => {
	return (
		<footer className="w-full bg-header-black p-4 z-50">
			<div className="footer-content flex justify-end text-zinc-300 font-mono">
				<div className="align-middle">
					<p>© 2024 Richard Gajda. All rights reserved.</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
